@import "/src/App.scss";

.base {
  position: relative;
  background-color: $bbr-brown;
  width: 100vw;
  height: 100vh;
  padding-top: 3.75rem;
  display: flex;
  flex-direction: column;
}

#allyTitleDesktop {
  display: none;
}

#allyTitleMobile {
  display: block;
  text-align: center;
  padding-bottom: 2rem;
}

#bbrQuizTitle {
  display: none;
}

.scrollable {
  overflow-y: hidden;
  overflow-x: hidden;
}

.underStepper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.miniTitle {
font-family: Sackers Gothic Medium;
font-size: 1rem;
font-weight: 850;
line-height: 19px;
letter-spacing: 0.02em;
text-align: left;
color: $bbr-peach;
text-transform: uppercase;
}

@media only screen and (min-width: 1130px) {
  .scrollable {
    overflow-y: auto;
    overflow-x: hidden;
  }
  #allyTitleDesktop {
    display: block;
  }
  #allyTitleMobile {
    display: none;
  }
  #bbrQuizTitle {
    display: block;
  }
  .underStepper {
    justify-content: space-between;
    padding-top: 1.875rem;
    padding-left: 19rem;
    padding-right: 19rem;
  }
}
