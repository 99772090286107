@import "/src/App.scss";

.completed {
  height: 100vh;
  display: grid;
  grid-template-rows: 0fr 1fr 0fr;
  // grid-auto-rows: 200px;
  grid-template-columns: 1fr;
  // gap: 0.625rem;
  // flex-direction: column;
  // justify-content: start;
}
.completedBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow-y: auto;
  overflow-x: hidden;

  padding-top: 3rem;
  // padding-bottom: 130px;
  h1 {
    display: none;
    text-transform: uppercase;
    font-family: Sackers Gothic Medium;
    font-size: 1.875rem;
    font-weight: 850;
    line-height: 2.25rem;
    letter-spacing: 0.1em;
    text-align: center;
    color: $bbr-ivory;
  }
}

.completedBody__title_mobile {
  display: block !important;
}
.completedResultContainer {
  position: relative;
  margin-top: 0.9375rem;
  margin-bottom: 1.5625rem;
  background-color: $bbr-ivory-20;
  border-radius: 100%;
  min-height: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    p {
      position: absolute;
      left: 25%;
      right: 25%;
      top: 9.5rem;
      color: $bbr-ivory;
      font-family: Century Gothic;
      // font-size: 1.25rem;
      font-size: 2rem;
      font-weight: 400;
      line-height: 1.875rem;
      letter-spacing: 0.02em;
      text-align: center;
    }

    h2 {
      color: $bbr-peach;
      font-family: Sackers Gothic Medium;
      // font-size: 5rem;
      font-size: 8rem;
      font-weight: 850;
      line-height: 3.75rem;
      letter-spacing: 0.02em;
      text-align: center;
    }
  }
  .outerElipsis {
    position: absolute;
    background-color: transparent;
    border-radius: 100%;
    border-color: $bbr-ivory-20;
  }

  $outer-ellipsis-border-margins: 3.4375rem;

  .outerElipsisOne {
    height: calc(100% + $outer-ellipsis-border-margins * 1);
    width: calc(100% + $outer-ellipsis-border-margins * 1);
    border-width: 0.1875rem;
  }
  .outerElipsisTwo {
    height: calc(100% + $outer-ellipsis-border-margins * 2);
    width: calc(100% + $outer-ellipsis-border-margins * 2);
    border-width: 0.125rem;
  }
  .outerElipsisThree {
    height: calc(100% + $outer-ellipsis-border-margins * 3);
    width: calc(100% + $outer-ellipsis-border-margins * 3);
    border-width: 0.0625rem;
  }
  .outerElipsisFour {
    height: calc(100% + $outer-ellipsis-border-margins * 4);
    width: calc(100% + $outer-ellipsis-border-margins * 4);
    border-width: 0.0313rem;
  }
}

.header {
  padding-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: start;
  color: $bbr-peach;
  text-transform: uppercase;
  font-size: 1rem;
  // padding-top: 1.875rem;
  padding-left: 3.25rem;
  padding-right: 3.25rem;
}

#bbrQuizTitle {
  display: none;
  text-align: center;
}

.miniTitle {
  font-family: Sackers Gothic Medium;
  font-size: 1rem;
  font-weight: 850;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: left;
}

.waveCompleted {
  // position: fixed;
  // display: none;
  margin-top: auto;
  position: relative;
  bottom: 0;
}

.emailInputForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;

  p {
    color: $bbr-ivory;
    font-family: Century Gothic;
    // font-size: 1.25rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.875rem;
    letter-spacing: 0.02em;
    text-align: center;
  }
  input {
    z-index: 100;
    background-color: rgba(247, 240, 228, 0.2);
    color: $bbr-ivory;
    font-family: "Century Gothic";
    font-size: 1.25rem;
    line-height: 1.875rem;
    border-radius: 0.3125rem;
    padding: 0.625rem 1.25rem;
    width: 100%;
  }
  // start placeholder styles
  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari/Edge */
    color: $bbr-ivory-20;
    font-family: "Century Gothic";
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.875rem;
    /* identical to box height, or 150% */

    letter-spacing: 0.02em;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $bbr-ivory-20;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.875rem;
    /* identical to box height, or 150% */

    letter-spacing: 0.02em;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    color: $bbr-ivory-20;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.875rem;
    /* identical to box height, or 150% */

    letter-spacing: 0.02em;
  }
  // end placeholder styles
  button {
    background-color: $bbr-peach;
    border-radius: 5px;
    padding: .625rem 1.25rem;
    font-family: "Century Gothic";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    /* identical to box height, or 167% */

    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $bbr-brown;
    width: 100%;
  }
}

.shareContainer {
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  p {
    font-size: 12px;
    letter-spacing: 0.8px;
    font-weight: bold;
  }

  .shareIcons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      margin: 6px;
    }
  }
}


@media only screen and (min-width: 1130px) {
  .completedBody {
    padding-top: 5.625rem;
  }
  .completedBody h1.completedBody__title_mobile {
    display: none !important;
  }
  .completedBody h1 {
    display: block;
  }
  .completedResultContainer {
    height: 350px;
    width: 350px;
  }
  .completedResultContainer div p {
    top: 5.625rem;
    font-size: 1.25rem;
  }
  .completedResultContainer div h2 {
    font-size: 5rem;
  }
  .header {
    padding-top: 6.25rem;
    display: flex;
    justify-content: space-between;
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
  #bbrQuizTitle {
    display: block;
  }

  .emailInputForm p {
    font-size: 1.25rem;
  }
  button {
    width: auto !important;
  }
}