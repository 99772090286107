@import "/src/App.scss";

$stepper-width: 14%;
$stepper-gap: 0.625rem;

.stepperContainer {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  // max-width: 14 * ($stepper-width + $stepper-gap);
  justify-content: center;
  // padding-left: 6.25rem;
  // padding-right: 6.25rem;
  gap: 0.625rem;
}
.stepperWrapper {
  width: $stepper-width;
  -webkit-transition: width 0.6s;
  -moz-transition: width 0.6s;
  -o-transition: width 0.6s;
  transition: width 0.6s;
}
.stepper {
  border-radius: 0.625rem;
  background-color: $bbr-ivory-20;
  height: 0.625rem;
}

.ivory {
  background-color: $bbr-ivory;
}

@media only screen and (min-width: 1130px) {
  .stepperContainer {
    padding-left: 19rem;
    padding-right: 19rem;
  }
}