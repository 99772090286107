@import "/src/App.scss";

.quizQuestion {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 100vw;
  width: 320px;
}

.questionEnum {
  padding-top: 4rem;
  padding-bottom: 2rem;

  color: $bbr-ivory-20;
  font-family: Century Gothic;
  // font-size: 32px;
  font-size: 3.2rem;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: 0.02em;
  text-align: center;
}

.questionTitle {
  font-family: Sackers Gothic Medium;
  font-size: 1.875rem;
  font-weight: 850;
  line-height: 2.25rem;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
  color: $bbr-ivory;

  padding-bottom: 4.375rem;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.answersContainer {
  display: grid;
  grid-template-rows: repeat(4, minmax(0, 80px));
  grid-template-columns: repeat(1, minmax(0, 320px));
  gap: 0.625rem;
  // padding-left: 6.25rem;
  justify-items: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 3.5rem;
  padding-bottom: 4.3rem;
}

.answersWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.answer {
  color: $bbr-ivory;
  border: 2px solid #f7f0e433;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Century Gothic;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.875rem;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;

  cursor: pointer;
}

.hasAnswered {
  background-color: $bbr-peach;
  color: $bbr-brown;
}

.answer:focus {
  padding: 0;
  margin: 0;
  opacity: 1;
  background: $bbr-peach;
  transition: 0.12s;
}

.answer:focus p {
  color: $bbr-brown;
}

@media only screen and (min-width: 1130px) {
  .questionEnum {
    padding-top: 1.0625rem;
    padding-bottom: 0.625rem;
  }
  .answersContainer {
    display: grid;
    grid-template-rows: repeat(2, minmax(0, 114px)) !important;
    grid-template-columns: repeat(2, minmax(0, 540px)) !important;
    gap: 0.625rem;
    padding: 0;
  }
  .questionTitle {
    padding-left: 0;
    padding-right: 0;
  }
  .answer {
    width: 540px !important;
    height: 114px !important;
  }
}
