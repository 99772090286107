@tailwind base;
@tailwind components;
@tailwind utilities;

$bbr-brown: #331715;
$bbr-ivory: #F7F0E4;
$bbr-peach: #F6A686;
$bbr-ivory-20: rgba(247, 240, 228, 0.2);


html {
  font-size: 10px;
}

img, video {
  width: 100%;
}

.main {
  background-color: $bbr-brown;
  width: 100vw;
  height: 100vh;
    overflow-y: auto;
}

.main div {
  // overflow-y: auto;
  // height: 100vh;
}


@media only screen and (min-width: 1130px) {
  html {
    font-size: 16px;
  }
}